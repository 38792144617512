<template>
  <div class="super-rebate">
<!--    <div class="rebate-head">-->
<!--      <img @click="goBack" class="back" src="https://cdn.cailu88.com/jingxuanshi/back_arrow.png" alt="">-->
<!--      <span>超返红包使用说明</span>-->
<!--      <img @click="refresh" class="refresh" src="https://cdn.cailu88.com/jingxuanshi/super_rebate_refresh.png" alt="">-->
<!--    </div>-->
    <div class="wrap-use-remind">
      <div class="top">
        <img src="https://cdn.cailu88.com/jingxuanshi/super_rebate_icon_1.png"/>
        <span>使用须知</span>
      </div>
      <div class="content">
        <p>
          1.淘宝/天猫实名制用户才可享受超值买返权益；
          <br/>
          2.超值买返红包每月月底清零，请注意红包的有效期；
          <br/>
          3.部分秒杀商品可能出现抢完情况，若下单后无返现金红包情况可退款选择其他商品购买。
        </p>
      </div>
    </div>
    <div class="wrap-use-process">
      <div class="top">
        <img src="https://cdn.cailu88.com/jingxuanshi/super_rebate_icon_2.png"/>
        <span>超值买返红包使用流程</span>
      </div>
      <div class="content">
        <img class="step" src="https://cdn.cailu88.com/jingxuanshi/super_rebate_step_1.png">
        <p class="bold">使用鲸选师至淘宝购买超值买返商品</p>
        <p>下单成功后立返现金红包至用户淘宝卡券包中</p>
        <img class="img-info" src="https://cdn.cailu88.com/jingxuanshi/super_rebate_img_1.png"/>
        <p style="color: #E44A59">必须显示超值买返价才会有返现金红包</p>
        <img class="img-info" src="https://cdn.cailu88.com/jingxuanshi/super_rebate_img_2.png"/>
        <p style="color: #E44A59">超返红包仅限手机天猫app购买实物商品使用</p>
      </div>
      <div class="content" style="margin-bottom: 18px">
        <img class="step" src="https://cdn.cailu88.com/jingxuanshi/super_rebate_step_2.png">
        <p class="bold">打开/下载【手机天猫App】<img class="tm-logo" src="https://cdn.cailu88.com/jingxuanshi/super_rebate_tm.png"/>，购买实物商品时即可使用</p>
        <img class="img-info" src="https://cdn.cailu88.com/jingxuanshi/super_rebate_img_3.png">
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'superRebate',
  data() {
    return {

    }
  },
  mounted () {
  },
  methods: {
    // goBack() {
    //   this.$router.go(-1)
    // },
    //
    // refresh() {
    //   this.$router.go(0)
    // }
  }
}
</script>
<style lang="less" scoped>
.super-rebate {
  min-height: 100vh;
  //.rebate-head{
  //  margin-top: 4px;
  //  color: #333333;
  //  display: flex;
  //  align-items: center;
  //  .back{
  //    margin-left: 15px;
  //    width: 9px;
  //    height: 16px;
  //  }
  //  span{
  //    margin: 0 auto;
  //    font-size: 18px;
  //    font-weight: 500;
  //    color: #333333;
  //    line-height: 25px;
  //  }
  //  .refresh{
  //    margin-right: 20px;
  //    width: 20px;
  //    height: 20px;
  //  }
  //}
  .wrap-use-remind {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    .top{
      margin-bottom: 4px !important;
    }
  }
  .wrap-use-process{
    .content{
      margin-top: 32px;
      .bold{
        font-size: 18px;
        font-weight: 800;
        color: #7F694E;
        line-height: 25px;
      }
      p{
        margin-left: 12px;
      }
      img{
        width: 320px;
      }
      .tm-logo{
        position: relative;
        top: 8px;
        width: 30px;
        height: 30px;
      }
      .img-info {
        margin-top: 12px;
        margin-bottom: 12px;
      }
    }
  }
  .wrap-use-remind,
  .wrap-use-process{
    margin: 0 auto;
    width: 355px;
    .top {
      display: flex;
      align-items: center;
      margin-left: 8px;
      img{
        width: 24px;
        height: 24px;
      }
      span{
        margin-left: 2px;
        font-size: 20px;
        font-weight: 800;
        color: #7F694E;
        line-height: 28px;
      }
    }
    .content {
      position: relative;
      width: 323px;
      background: #FEF7DE;
      border-radius: 10px;
      padding: 18px 16px;
      p{
        font-size: 15px;
        font-weight: 400;
        color: #7F694E;
        line-height: 24px;
      }
      .step {
        position: absolute;
        top: -17px;
        width: 34px;
        height: 34px;
      }
    }
  }
}
</style>
